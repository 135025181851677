<template>
  <div>
    <div class="row broadcasts-page">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="datatable.queries.per_page" class="ml-1 mr-1" size="sm"
                      :options="[10, 15, 25, 50, 100]" @change="getBroadcasts"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-6">
                <div class="dataTables_filter text-md-right">
                  <b-button variant="primary" type="button" size="sm" class="mr-2" :disabled="loadingBroadcasts"
                    @click="refresh">
                    <i class="uil uil-refresh"></i> Refresh
                  </b-button>
                  <b-button variant="primary" type="button" size="sm" @click="onNewBroadcast">
                    <i class="uil uil-plus"></i> New broadcast
                  </b-button>
                </div>
              </div>
              <div class="col-sm-12">
                <div class="d-flex align-items-center float-right">
                  Status:
                  <select v-model="datatable.queries.status" class="form-control ml-2 form-control-sm"
                    style="width: 250px;">
                    <option value="">All</option>
                    <option value="in-progress">In Progress</option>
                    <option value="completed">Completed</option>
                    <option value="paused">Paused</option>
                    <option value="draft">Draft</option>
                    <option value="scheduled">Scheduled</option>
                  </select>
                  <b-form-input
                    v-model="datatable.queries.search"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                    style="width: 250px;"
                  ></b-form-input>
                  <button class="ml-1 btn btn-primary btn-sm" @click="onClickSearch">
                    <i class="uil uil-search-alt"></i>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="!loadingBroadcasts" class="mb-0">
              <b-table :items="broadcasts" :fields="datatable.columns" :sort-by.sync="datatable.queries.sort_by"
                :sort-desc.sync="datatable.queries.sort_desc" :per-page="datatable.queries.per_page"
                :no-local-sorting="true" :tbody-tr-class="rowClass" @sort-changed="sortChanged">
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>

                <template v-slot:cell(send_at)="data">
                  {{ data.item.status === 'Completed' ? data.item.send_at : '' |
                    datetime($store.getters['auth/dateFormat'] + ' hh:mm A') }}
                </template>

                <template v-slot:cell(scheduled_at)="data">
                  <span v-if="data.item.status === 'completed'">
                    {{ data.item.completed_at | datetime($store.getters['auth/dateFormat'] + ' hh:mm A',
                      data.item.timezone) }}
                  </span>
                  <span v-else-if="data.item.scheduled_at">
                    {{ data.item.scheduled_at | datetime($store.getters['auth/dateFormat'] + ' hh:mm A',
                      data.item.timezone) }}
                    <!-- <span v-if="data.item.timezone"> - ( {{ data.item.timezone }} )</span> -->
                  </span>
                </template>

                <template v-slot:cell(total_revenue)="data">
                  {{ data.item.total_revenue | currency }}
                </template>

                <template v-slot:cell(delivered_percent)="data">
                  <span :class="{
                    'text-danger': data.item.delivered_percent <= 30,
                    'text-primary':
                      data.item.delivered_percent > 30 &&
                      data.item.delivered_percent <= 90,
                    'text-success': data.item.delivered_percent > 90,
                  }">
                    {{ data.item.delivered_percent }}
                  </span>
                </template>

                <template v-slot:cell(delivered_email)="data">
                  <span :class="{
                    'text-danger': data.item.delivered_percent <= 30,
                    'text-primary':
                      data.item.delivered_percent > 30 &&
                      data.item.delivered_percent <= 90,
                    'text-success': data.item.delivered_percent > 90,
                  }">
                    {{ data.item.delivered_percent }}%
                  </span><br/>
                  <span class="sub">{{ data.item.total_delivered }}</span>
                </template>
                <template v-slot:cell(opened_email)="data">
                  <div :id="`opened_email_${data.item.id}`" class="d-block opened_field">
                    <span :class="{
                      'text-danger': data.item.opened_percent <= 30,
                      'text-primary':
                        data.item.opened_percent > 30 &&
                        data.item.opened_percent <= 90,
                      'text-success': data.item.opened_percent > 90,
                    }">
                      {{ data.item.opened_percent }}%
                    </span><br/>
                    <span class="sub">{{ data.item.total_opened }}</span>
                  </div>
                  <!-- HTML title specified via default slot -->
                  <b-tooltip :target="`opened_email_${data.item.id}`" placement="bottom">
                    <span><b>Subject:</b> {{ data.item.subject }} </span><br/>
                    <span v-if="data.item.preheader"><b>Preheader:</b> {{ data.item.preheader }}</span>
                  </b-tooltip>
                </template>

                <template v-slot:cell(clicked_email)="data">
                  <span :class="{
                    'text-danger': data.item.clicked_percent <= 30,
                    'text-primary':
                      data.item.clicked_percent > 30 &&
                      data.item.clicked_percent <= 90,
                    'text-success': data.item.clicked_percent > 90,
                  }">
                    {{ data.item.clicked_percent }}%
                  </span><br/>
                  <span class="sub">{{ data.item.total_clicked }}</span>
                </template>

                <template v-slot:cell(bounce_email)="data">
                  <span :class="{
                    'text-danger': data.item.bounce_percent <= 30,
                    'text-primary':
                      data.item.bounce_percent > 30 &&
                      data.item.bounce_percent <= 90,
                    'text-success': data.item.bounce_percent > 90,
                  }">
                    {{ data.item.bounce_percent }}%
                  </span><br/>
                  <span class="sub">{{ data.item.total_bounce }}</span>
                </template>

                <template v-slot:cell(spamreport_email)="data">
                  <span :class="{
                    'text-danger': data.item.spamreport_percent <= 30,
                    'text-primary':
                      data.item.spamreport_percent > 30 &&
                      data.item.spamreport_percent <= 90,
                    'text-success': data.item.spamreport_percent > 90,
                  }">
                    {{ data.item.spamreport_percent }}%
                  </span><br/>
                  <span class="sub">{{ data.item.total_spamreport }}</span>
                </template>

                <template v-slot:cell(unsubscribed_email)="data">
                  <span :class="{
                    'text-danger': data.item.unsubscribed_percent <= 30,
                    'text-primary':
                      data.item.unsubscribed_percent > 30 &&
                      data.item.unsubscribed_percent <= 90,
                    'text-success': data.item.unsubscribed_percent > 90,
                  }">
                    {{ data.item.unsubscribed_percent }}%
                  </span><br/>
                  <span class="sub">{{ data.item.total_unsubscribed }}</span>
                </template>

                <template v-slot:cell(conversions)="data">
                  <span :class="{
                    'text-danger': data.item.conversions <= 30,
                    'text-primary':
                      data.item.conversions > 30 &&
                      data.item.conversions <= 90,
                    'text-success': data.item.conversions > 90,
                  }">
                    {{ data.item.conversions }}
                  </span>
                </template>

                <template v-slot:cell(status)="data">
                  <span class="text-capitalize" :class="{
                    'text-success': data.item.status === 'completed',
                    'text-primary': data.item.status === 'in-progress' || data.item.status === 'scheduled',
                  }">
                    {{ data.item.status }}
                  </span>
                </template>

                <template v-slot:cell(actions)="data">
                  <div class="table-actions-group" style="min-width: 250px">
                    <router-link
                      :to="{
                        name: 'business.broadcasts.edit',
                        params: { id: data.item.id },
                      }"
                      class="btn btn-outline-dark"
                    >
                      <i class="uil uil-edit"></i> Edit
                    </router-link>
                    <b-dropdown
                      variant="outline-dark"
                      right
                    >
                      <template v-slot:button-content>
                        <i class="uil uil-angle-down"></i>
                      </template>
                      <b-dropdown-item v-if="data.item.completed_at" @click="onClickResend(data.item)">
                        <i class="uil uil-refresh mr-2"></i> Resend Options
                      </b-dropdown-item>

                      <b-dropdown-item @click="viewMessage(data.item)">
                        <i class="uil uil-eye mr-2"></i> View message
                      </b-dropdown-item>

                      <b-dropdown-item :to="{
                        name: 'business.broadcasts.report',
                        params: { id: data.item.id },
                      }">
                        <i class="uil uil-analysis mr-2"></i> Reporting
                      </b-dropdown-item>

                      <b-dropdown-item @click="duplicate(data.item)">
                        <i class="uil uil-copy-alt mr-2"></i> Duplicate
                      </b-dropdown-item>

                      <b-dropdown-item v-if="data.item.status === 'in-progress'" @click="onStop(data.item)">
                        <span class="text-danger"><i class="uil uil-stop-circle mr-2"></i> Stop</span>
                      </b-dropdown-item>

                      <b-dropdown-item @click="onDelete(data.item)">
                        <span class="text-danger">
                          <i class="uil uil-trash mr-2"></i> Delete
                        </span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
              <div class="mt-4">
                <div class="float-left mt-2">
                  Total {{ totalBroadcasts }} broadcasts
                </div>
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination v-model="datatable.queries.page" :total-rows="totalBroadcasts"
                      :per-page="datatable.queries.per_page" @change="onPageChanged"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showViewMessage" :title="`Broadcast: ${selectedBroadcast ? selectedBroadcast.name : ''}`" size="lg">
      <div v-if="selectedBroadcast">
        <!-- eslint-disable vue/no-v-html -->
        <div v-if="provider === 'email'" class="overflow-hidden">
          <div v-if="selectedBroadcast.subject" v-html="selectedBroadcast.subject"></div><br/>
          <div v-html="selectedBroadcast.message"></div>
        </div>
        <!--eslint-enable-->
        <p v-else>
          {{ selectedBroadcast.message }}
        </p>
        <div>
          <img v-for="(img, index) in selectedBroadcast.images" :key="index" :src="img.path" class="mr-2" width="100px" />
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="primary" @click="showViewMessage = false">Close</b-button>
      </template>
    </b-modal>
    <b-modal v-model="showDeleteModal" title="Delete Broadcast">
      <p v-if="selectedBroadcast">Are you sure you want to delete the broadcast <b>"{{
        selectedBroadcast.name
      }}"</b>?</p>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false">Cancel</b-button>
        <b-button :disabled="processing" variant="danger" @click="handleDestroy">
          <b-spinner v-if="processing" small />
          <span v-else>Delete</span>
        </b-button>
      </template>
    </b-modal>
    <b-modal v-model="showStopModal" title="Stop Broadcast">
      <p v-if="selectedBroadcast">Are you sure you want to stop running this broadcast <b>"{{
        selectedBroadcast.name
      }}"</b>?</p>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showStopModal = false">Cancel</b-button>
        <b-button :disabled="processing" variant="danger" @click="handleStop">
          <b-spinner v-if="processing" small />
          <span v-else>Stop</span>
        </b-button>
      </template>
    </b-modal>
    <b-modal v-model="showResendModal" title="Resend This Broadcast">
      <div class="form-group">
        <b-form-radio v-model="resendOptionSelected" name="isVerified" :value="1" class="d-block custom-checkbox-s">Send to new contacts (since this campaign was originally sent)</b-form-radio>
        <b-form-radio v-if="provider === 'email'" v-model="resendOptionSelected" name="isVerified" :value="2" class="d-block custom-checkbox-s mt-3">Send to contacts who have not read/opened this campaign</b-form-radio>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showResendModal = false">Cancel</b-button>
        <b-button variant="primary" @click="onContinueResend">Continue <i class="uil uil-arrow-right"></i></b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment-timezone'

export default {
  components: {
  },

  props: {
    provider: {
      type: String,
      default: null,
    }
  },

  data() {
    return {
      processing: false,
      loadingKeyword: false,
      showDeleteModal: false,
      broadcast: {},
      dateTimePicker: {
        enableTime: false,
        dateFormat: 'Y-m-d',
      },
      timePicker: {
        enableTime: true,
        noCalendar: true,
        dateFormat: 'G:i K',
      },
      datatable: {
        items: [],
        totalItems: 1,
        columns: [
        ],
        queries: {
          per_page: 10,
          page: 1,
          search: null,
          sort_by: 'send_at',
          sort_desc: true,
          status: '',
          provider: null,
        },
      },
      loadingBroadcasts: false,
      showViewMessage: false,
      selectedBroadcast: null,
      messageCredit: null,
      loadingCredit: false,
      showResendModal: false,
      resendOptionSelected: 1,
      showStopModal: false,
    }
  },

  computed: {
    timezones() {
      return this.$store.getters['app/timezones']
    },

    user() {
      return this.$store.getters['auth/user']
    },

    userTimezone() {
      return this.user && this.user.business ? this.user.business.timezone : moment.tz.guess()
    },

    agencyEmail() {
      return this.user && this.user.business.agency.email;
    },

    broadcasts() {
      return this.$store.getters['broadcast/all'] || []
    },

    totalBroadcasts() {
      return this.$store.getters['broadcast/total']
    },
  },

  mounted() {
    if (this.provider === 'email') {
      this.datatable.columns = [
        { key: 'name', sortable: true, thClass: 'text-center', tdClass: 'text-center align-middle', },
        { key: 'total_sent', label: 'Requests', thClass: 'text-center', tdClass: 'email-td-class', },
        { key: 'delivered_email', label: 'Delivered', thClass: 'text-center', tdClass: 'email-td-class', },
        { key: 'opened_email', label: 'Opened', thClass: 'text-center', tdClass: 'email-td-class', sortable: true},
        { key: 'clicked_email', label: 'Clicked', thClass: 'text-center', tdClass: 'email-td-class', sortable: true},
        { key: 'bounce_email', label: 'Bounces', thClass: 'text-center', tdClass: 'email-td-class',  },
        { key: 'spamreport_email', label: 'Spam Reports', thClass: 'text-center', tdClass: 'email-td-class', },
        { key: 'unsubscribed_email', label: 'Unsubscribes', thClass: 'text-center', tdClass: 'email-td-class', },
        { key: 'redeemeds', label: 'Redeemed', thClass: 'text-center', tdClass: 'email-td-class', },
        { key: 'total_revenue', label: 'Revenue', thClass: 'text-center', tdClass: 'email-td-class', },
        { key: 'status', thClass: 'text-center', tdClass: 'text-center align-middle', },
        { key: 'scheduled_at', label: 'Date', sortable: true, thClass: 'text-center', tdClass: 'text-center align-middle', },
        { key: 'actions', thClass: 'text-center', tdClass: 'text-center align-middle', },
      ];
    } else {
      this.datatable.columns = [
        { key: 'name', sortable: true, class: 'text-center align-middle' },
        { key: 'total_sent', class: 'text-center align-middle' },
        { key: 'total_failed', class: 'text-center align-middle' },
        { key: 'delivered_percent', label: 'Delivered %', class: 'text-center align-middle' },
        { key: 'redeemeds', label: 'Redeemed', class: 'text-center align-middle' },
        { key: 'conversions', label: 'Conversions %', class: 'text-center align-middle' },
        { key: 'total_revenue', class: 'text-center align-middle' },
        { key: 'status', class: 'text-center align-middle' },
        { key: 'scheduled_at', label: 'Date', sortable: true, class: 'text-center align-middle' },
        { key: 'actions', class: 'text-center align-middle' },
      ];
    }
    if (this.$store.getters['broadcast/all'] === null) {
      this.getBroadcasts()
    } else {
      this.datatable.queries = this.$store.getters['broadcast/queries']
      if (this.datatable.queries.provider !== this.provider) {
        this.getBroadcasts()
      }
    }
  },

  methods: {
    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.page = 1
      this.getBroadcasts()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getBroadcasts()
    },

    onClickSearch() {
      this.datatable.queries.page = 1
      this.getBroadcasts()
    },

    onNewBroadcast() {
      this.$router.push({ name: 'business.broadcasts.create', query: { type: this.provider } })
    },

    getBroadcasts() {
      this.loadingBroadcasts = true
      let param = {
        ...this.datatable.queries,
        provider: this.provider
      }

      this.$store.dispatch('broadcast/getAll', param)
        .then(() => {
          this.loadingBroadcasts = false
        })
        .catch(() => {
          this.loadingBroadcasts = false
        })
    },

    refresh() {
      this.getBroadcasts()
    },

    viewMessage(broadcast) {
      this.showViewMessage = true
      this.selectedBroadcast = broadcast
    },

    duplicate(broadcast) {
      this.$router.push({ name: 'business.broadcasts.duplicate', params: { id: broadcast.id } })
    },

    onClickResend(broadcast) {
      this.showResendModal = true;
      this.selectedBroadcast = broadcast
    },

    onContinueResend() {
      this.showResendModal = false
      this.$router.push({ name: 'business.broadcasts.resend', params: { id: this.selectedBroadcast.id }, query: { type: this.resendOptionSelected } })
    },

    onStop(broadcast) {
      this.selectedBroadcast = broadcast
      this.showStopModal = true;
    },

    handleStop() {
      this.processing = true
      this.$store
        .dispatch('broadcast/stop', this.selectedBroadcast)
        .then(() => {
          this.showStopModal = false
          this.processing = false
          this.getBroadcasts()
        })
        .catch(() => {
          this.processing = false
        })
    },

    onDelete(broadcast) {
      this.showDeleteModal = true;
      this.selectedBroadcast = broadcast
    },

    handleDestroy() {
      this.processing = true
      this.$store
        .dispatch('broadcast/destroy', this.selectedBroadcast)
        .then(() => {
          this.showDeleteModal = false
          this.processing = false
          this.getBroadcasts()
        })
        .catch(() => {
          this.processing = false
        })
    },

    rowClass(item) {
      return item.status === 'draft' ? 'draft' : ''
    }
  },
}
</script>

<style lang="scss">
#new-broadcast {
  .flatpickr-input {
    background: #FFF !important;
  }
}

.broadcasts-page {
  tr.draft {
    background-color: #eaeaea;
  }
  .email-td-class {
    text-align: center;
    vertical-align: middle;
    font-size: 17px;
    span.sub{
      font-size: 14px;
    }
  }
  .opened_field {
    cursor: pointer;
  }
}

.new-broadcast-type {
  .btn {
    width: 100px;
    height: 100px;
    border-width: 2px;
  }
}
</style>